<template>
<div>
    <div class="wrap">
        <form name="mfrm" action="/menu_management_status/index" method="post">
            <div class="con_top">
                <h2><span>■</span> 상담일지관리</h2>
            </div>
            <div class="con">
                <div class="con_table">
                    <div class="search2">
                        <div class="float-left dp-block mb-10">
                            <input type="text" v-model="value" @keyup.enter="getReportList()" class="w-200px h-40px pd-00 pl-20" placeholder="고객명">
                            <a @click="getReportList()" class="btn_search ml-10 pointer">Search<span></span></a>
                        </div>

                        <input type="hidden" name="_token" id="_token" value="EqLJL0tVa23F5ikcDmzYVhVNw50lB9k5eDl840Bi">
                        <input type="hidden" name="this_month" id="this_month" value="2020-10">
                        <div class="float-right dp-block mb-10">예약등록일검색 :
                            <crm-datepicker v-model="startDate" :parentDate="startDate" format="yyyy-MM-dd" :language="$store.state.ko"></crm-datepicker> ~
                            <crm-datepicker v-model="endDate" :parentDate="endDate" class="mr-10" format="yyyy-MM-dd" :language="$store.state.ko"></crm-datepicker>
                            <a class="btn_search pointer" @click="getReportList()">Search<span></span></a>
                        </div>
                    </div>
                    <table class="default_table mt-20">
                        <tbody v-if="loading">
                            <tr>
                                <th>No</th>
                                <th>상담일날짜</th>
                                <th>고객</th>
                                <th>상담유형</th>
                                <th>진행현황</th>
                                <th>일지작성</th>
                            </tr>
                            <tr>
                                <td colspan="8">검색중입니다.</td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <th>No</th>
                                <th>상담일날짜</th>
                                <th>고객</th>
                                <th>상담유형</th>
                                <th>진행현황</th>
                                <th>일지작성</th>
                            </tr>
                            <tr v-for="(item, index) of reportList" :key="index">
                                <td>{{item.rownum}}</td>
                                <td>{{item.consDate2}}</td>
                                <td v-if="item.reportYn === '등록'" class="underline pointer font_color" @click="detail(item.idxReservation)">{{item.clientName}}</td>
                                <td v-if="item.reportYn !== '등록'" >{{item.clientName}}</td>
                                <td>{{item.eapType}}</td>
                                <td>{{item.processStatus}}</td>
                                <!--
                                    reportYn : 등록
                                      => 수정
                                    reportYn : 미등록
                                      => 등록
                                -->
                                <td>
                                    <div v-if="item.reportYn === '미등록'">
                                        <span class="underline pointer" @click="register(item.reportYn, item.idxReservation)">등록</span>
                                    </div>
                                    <div v-else> 
                                        <span class="underline pointer" @click="preview(reportList[index])">미리보기</span>
                                        <span v-if="(item.requestModify && item.requestModify === 'Y')"> | </span>
                                        <span class="underline pointer font_color bold" v-if="(item.requestModify && item.requestModify === 'Y')" @click="modify(item.idxReservation)">수정</span>
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="reportList.length === 0">
                                <td colspan="8">검색 결과가 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="con_table">
                    <div class="page">
                        <ul>
                            <li>
                                <a class="pointer" @click="prevPaging()"> &lt; </a>
                            </li>
                            <li v-for="index in calcMaxPaging()" :key="index" class="txt-blue">
                                <span class="pointer" style="padding: 8px;" @click="paging(index)">
                                    <a :class="{'bold': index === pageNum}">{{index}}</a>
                                </span>
                            </li>
                            <li>
                                <a class="pointer" @click="nextPaging()"> &gt; </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <preview :onModal.sync="onModal" :report.sync="report"></preview>
</div>
</template>
<script>
import Preview from '@/pages/counselor/journalPreview.vue'
export default {

    components: {
        Preview
    },

    data: () => ({
        startDate: '', // 기간검색-시작날짜
        endDate: '', // 기간검색-종료날짜
        value: '',
        idxCrmCenter: -1,
        idxCrmMetaProduct: -1,
        reportList: [],
        report: {},

        // 페이징에 필요한 변수들
        pageNum: 1,
        pageSize: 50,
        listLength: 0, // 리스트 size
        maxPagingCount: 1, // 최대 페이지 수
        maxPagePart: 1, // 최대 파트가 몇번째인지
        pagePart: 1, // 현재 페이징이 몇번째 파트에 있는지 (ex 1~10 1파트, 11~20 2파트)

        onModal: false, // 미리보기 모달 on/off
        genreList:[],
        typeList:[],
        loading:true,
        auth:'',
    }),

    mounted() {

        if(sessionStorage.getItem('pageNum') !== 'undefined' && sessionStorage.getItem('pageNum') !== null) {
            this.pageNum = Number(sessionStorage.getItem('pageNum'))
        }
        if(sessionStorage.getItem('value') !== 'undefined' && sessionStorage.getItem('value') !== null) {
            this.value = sessionStorage.getItem('value')
        }
        if(sessionStorage.getItem('startDate') !== 'undefined' && sessionStorage.getItem('startDate') !== null) {
            this.startDate = sessionStorage.getItem('startDate')
        }
        if(sessionStorage.getItem('endDate') !== 'undefined' && sessionStorage.getItem('endDate') !== null) {
            this.endDate = sessionStorage.getItem('endDate')
        }
        if(sessionStorage.getItem('idxCrmCenter') !== 'undefined' && sessionStorage.getItem('idxCrmCenter') !== null) {
            this.idxCrmCenter = sessionStorage.getItem('idxCrmCenter')
        }

        this.getReportList();
        this.getGenreMeta();/* 회기 형태 리스트 */
        this.getTypeMeta();/*  상담 형태 리스트 */
        this.auth = localStorage.getItem("auth");
    },

    methods: {

        // 상담일지 미리보기 (모달 on)
        preview(reportList) {
            this.report = reportList;
            const selectedGenre = this.genreList.filter(v=> v.idx === reportList.idxGenreMeta);
            const selectedType = this.typeList.filter(v=> v.idx === reportList.idxTypeMeta);
            if(selectedGenre.length > 0){
                this.report.idxGenreMetaNm = selectedGenre[0].cons_title;
            }else{
                this.report.idxGenreMetaNm = '';
            }

            if(selectedType.length > 0){
                this.report.idxTypeMetaNm  = selectedType[0].cons_title;
            }else{
                this.report.idxTypeMetaNm  ='';
            }
            
            this.axios.get(`/api/v1/cons/report/${this.report.idxReservation}`)
            .then((res)=>{
                const { data } = res;
                if(data.err === 0){
                    const selectedGenre = this.genreList.filter(v=> v.idx === data.reportVO.idxGenreMeta);
                    const selectedType = this.typeList.filter(v=> v.idx === data.reportVO.idxTypeMeta);
                    let genreMetaNm ='';
                    let typeMetaNm = '';
                     if(selectedGenre.length > 0){
                        genreMetaNm = selectedGenre[0].cons_title;
                    }else{
                        genreMetaNm = '';
                    }

                    if(selectedType.length > 0){
                        typeMetaNm  = selectedType[0].cons_title;
                    }else{
                        typeMetaNm ='';
                    }
                    const reportObj ={
                        'idxGenreMetaNm' : genreMetaNm,
                        'idxTypeMetaNm' : typeMetaNm,
                        'goal' : data.clientVO.goal,
                        'plan' : data.clientVO.plan,
                        'dangerStepTitle' : data.reportVO.dangerStepTitle,
                        'dangerStepInfo' :data.reportVO.dangerStepInfo,
                        'mainProblem' : data.reportVO.mainProblem,
                        'consProcess' : data.reportVO.consProcess,
                        'consContents' : data.reportVO.consContents,
                        'consOpinion' : data.reportVO.consOpinion,
                        'mentalTest' : data.reportVO.mentalTest,
                        'nextPlan' : data.reportVO.nextPlan,
                        'attachFileUrl' : data.reportVO.attachFileUrl,
                        'attachFileName' : data.reportVO.attachFileName,
                        'attachFileUrl2' : data.reportVO.attachFileUrl2,
                        'attachFileName2' : data.reportVO.attachFileName2,
                        'attachFileUrl3' : data.reportVO.attachFileUrl3,
                        'attachFileName3' : data.reportVO.attachFileName3,
                        'attachFileUrl4' : data.reportVO.attachFileUrl4,
                        'attachFileName4' : data.reportVO.attachFileName4,
                        'attachFileUrl5' : data.reportVO.attachFileUrl5,
                        'attachFileName5' : data.reportVO.attachFileName5,
                    };

                    this.report = reportObj;
                    this.onModal = !this.onModal;
                }else{
                    alert('조회를 실패하였습니다.');
                }
            }).catch(err => {
                if (err.response.data.path == '/login/msg') {
                    alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                    this.$router.push('/login')
                } else {
                    alert('관리자에게 문의하세요')
                }
            })
        },

        // 상담일지 닫기 (모달 off)
        offModal() {
            this.onModal = false
        },

        // 이전 페이징
        prevPaging() {
            if (this.pageNum === 1) {
                return false
            } else {
                this.pageNum -= 1
                this.getReportList()
            }
        },
        paging(index) {
            this.pageNum = index
            this.getReportList()
        },

        // 현재 페이지의 페이지 범위 계산
        calcMaxPaging() {
            if (this.maxPagingCount > this.pagePart * 10) {
                this.maxPagePart = this.pagePart * 10
            } else {
                this.maxPagePart = this.maxPagingCount
            }
            var pagingArray = []
            for (var i = ((this.pagePart - 1) * 10) + 1; i <= this.maxPagePart; i++) {
                pagingArray.push(i)
            }
            return pagingArray
        },

        // 다음 페이징
        nextPaging() {
            if (this.pageNum === this.maxPagingCount) {
                return false
            } else {
                this.pageNum += 1
                this.getReportList()
            }
        },

        // 오늘 날짜 YYYY-MM-DD로 지정하는 함수
        formatDate(date) {
            if (date) {
                let d = new Date(date),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();
                if (month.length < 2) month = '0' + month;
                if (day.length < 2) day = '0' + day;
                return [year, month, day].join('-');
            } else if (date === '') {
                return ''
            } else {
                let d = new Date(),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();
                if (month.length < 2) month = '0' + month;
                if (day.length < 2) day = '0' + day;
                return [year, month, day].join('-');
            }
        },

        // 상담일지 목록
        getReportList() {
            var params = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                value: this.value,
                startDate: this.startDate,
                endDate: this.endDate,
                idxCrmCenter: this.idxCrmCenter
            }
            sessionStorage.setItem('pageNum', this.pageNum)
            sessionStorage.setItem('value', this.value)
            sessionStorage.setItem('startDate', this.formatDate(this.startDate))
            sessionStorage.setItem('endDate', this.formatDate(this.endDate))
            sessionStorage.setItem('idxCrmCenter', this.idxCrmCenter)
            
            this.loading = true;
            
            this.axios.get('/api/v1/cons/report/list', {
                params: params
            })
            .then(res => {
                    if (res.data.err === 0) {
                        this.reportList = res.data.reportList

                        if (res.data.reportListCount) {
                            this.listLength = res.data.reportListCount
                        }
                        this.maxPagingCount = Math.ceil(this.listLength / this.pageSize)
                        this.maxPagePart = Math.ceil(this.maxPagingCount / 10)
                        this.pagePart = Math.ceil(this.pageNum / 10)
                    } else {
                        //alert('검색 결과가 없습니다.')
                        this.reportList = []
                    }
            })
            .catch(err => {
                console.log(err)
                if (err.response.data.path == '/login/msg') {
                    alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                    this.$router.push('/login')
                } else {
                    alert('관리자에게 문의하세요')
                }
            }).finally(()=>{
                this.loading = false;
            });
        },

        // 상담일지 상세내용
        detail(idx) {
            this.$router.push(`/counselor/journal_info?idx=${idx}`)
            //window.open(`/counselor/journal_info?idx=${idx}`, '_blank')
        },

        // 상담일지 등록페이지로 이동
        register (reportYn, idxCrmReservation) {
            if(reportYn !== '등록') {
                this.$router.push(`/counselor/journal_register?idxCrmReservation=${idxCrmReservation}`)
            } else {
                alert('이미 작성한 일지가 있으므로 등록할 수 없습니다.')
            }
        },

        // 상담일지 수정페이지로 이동
        modify (idxCrmReservation) {
            this.$router.push(`/counselor/journal_modify?idxCrmReservation=${idxCrmReservation}`);
        },

        /**
         * 회기유형 목록
         */
        getGenreMeta(){
            this.axios.get(`/api/v1/cons/report/genre_meta`,{}).then((response)=>{
                this.genreList = response.data.data;
            })
            .catch(err => {
                if (err.response.data.path == '/login/msg') {
                    alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                    this.$router.push('/login')
                } else {
                    alert('관리자에게 문의하세요')
                }
            });
        },

        /**
         * 상담형태 목록
         */
        getTypeMeta(){
            this.axios.get(`/api/v1/cons/report/type_meta`,{}).then((response)=>{
                this.typeList = response.data.data;
            })
            .catch(err => {
                if (err.response.data.path == '/login/msg') {
                    alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                    this.$router.push('/login')
                } else {
                    alert('관리자에게 문의하세요')
                }
            });
        }
    }
}
</script>
